import React, { useState, useCallback } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import ReactCompareImage from 'react-compare-image';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import 'react-image-gallery/styles/css/image-gallery.css';
import ImageGallery from 'react-image-gallery';

import ImageBefore from '../../static/images/before.jpg';
import ImageAfter from '../../static/images/after.jpg';

import styles from './gallery.module.scss';

const TABS = ['All', 'Carpet', 'Janitorial', 'Blinds', 'Floor'];

const HEADER_LABEL = {
  description: 'Description',
};

const Gallery = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const onSelectTab = useCallback(
    (tabIndex) => {
      setActiveTabIndex(tabIndex);
    },
    [],
  );

  const { contentfulGalleryPage, allContentfulGalleryPost } = useStaticQuery(graphql`
    {
      contentfulGalleryPage {
        title
        subtitle
      }
      allContentfulGalleryPost {
        nodes {
          description
          images {
            file {
              url
            }
          }
        }
      }
    }
  `);

  return (
    (
      <main>
        {/* HERO */}
        <section className={styles.hero}>
          <div className="container">
            <div className={styles.heroWrapper}>
              <div className={styles.heroDescription}>
                <h1 className="heading">{contentfulGalleryPage.title}</h1>
                <h2 className="subheading">{contentfulGalleryPage.subtitle}</h2>
                {/* <Button label="Calculate price" /> */}
              </div>
              <div className={styles.compareImageWrapper}>
                <ReactCompareImage
                  leftImage={ImageBefore}
                  leftImageAlt="carpet before cleaning"
                  rightImage={ImageAfter}
                  rightImageAlt="carpet after cleaning"
                />
              </div>
            </div>

          </div>
        </section>
        {/* Gallery */}
        <section className={styles.gallery}>
          <div className="container">
            <Tabs onSelect={onSelectTab} selectedTabClassName={styles.tabActive}>
              <TabList className={styles.tabList}>
                {TABS.map((tab) => <Tab key={tab} className={styles.tab}>{tab}</Tab>)}
              </TabList>

              {TABS.map((tab) => (
                <TabPanel className={styles.panel} key={tab}>
                  {allContentfulGalleryPost.nodes
                    .filter((item) => (
                      activeTabIndex === 0
                        ? item
                        : item.service.includes(tab)))
                    .map((item) => (
                      <article className={styles.galleryArticle} key={item.title}>
                        <h3 className={styles.galleryArticleTitle}>{item.title}</h3>
                        <header className={styles.galleryArticleHeader}>
                          <ul className={styles.galleryHeaderList}>
                            {Object.entries(item)
                              .filter(([label]) => Object.keys(HEADER_LABEL).includes(label))
                              .map(([label, value]) => (
                                <li className={styles.galleryHeaderListItem} key={label}>
                                  <h6 className={styles.galleryHeaderListLabel}>
                                    {HEADER_LABEL[label]}
                                  </h6>
                                  <p className={styles.galleryHeaderListValue}>
                                    {value}
                                  </p>
                                </li>
                              ))}
                          </ul>
                        </header>
                        <ImageGallery
                          items={
                            item
                              .images
                              .map((image) => (
                                {
                                  original: image.file.url,
                                  thumbnail: image.file.url,
                                }
                              ))
                          }
                        />
                      </article>
                    ))}
                </TabPanel>
              ))}

            </Tabs>
          </div>
        </section>
      </main>
    )
  );
};

export default Gallery;
